.gallery{
    width: 100%;

}

.gallery-wrapper{
    padding: 50px 150px;
}

.image-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    /* grid-auto-rows: minmax(100px, auto); */
}

.gallery-img{
    
    position: relative;
    max-width: 100%;
    min-width: 80%;
    overflow: hidden;
   
}

.gallery-img img{
    height: 250px;
    object-fit: cover;
    max-width: 100%;
    min-width: 80%;
    transition: all 0.2s linear;
}

.gallery-img:hover img{
    transform: scale(1.2);
}

.img-desc{
    position: absolute;
    top:30px;
    left: 50px;
    width: 70%;
    height: 70%;
    border: 2px solid burlywood;
    display: none;
}

.gallery-img:hover .img-desc{
    display: block;
}

.img-desc h6{
    color: white;
    font-size: 1rem;
}

@media screen and (max-width: 960px) {
    .gallery-wrapper{
        padding: 50px 10px;
    }
    
    .image-grid{
        
        grid-template-columns: repeat(1, 1fr);
       
        /* grid-auto-rows: minmax(100px, auto); */
    }

    .gallery-img{
    
        
        min-width: 100%;
        
       
    }
    
    .gallery-img img{
        
        min-width: 100%;
      
    }
}