.titlesection{
    width: 100%;
    padding: 50px 0px 100px 0px;
}

.titlesection-wrapper{
    display: flex;
    justify-content: space-evenly;
    padding: 20px 100px;
}

.titlesection-wrapper-content{
    width: 25%;
    /* margin-left: 100px; */
}

@media screen and (max-width: 960px) {

    .titlesection{
        width: 100%;
        height: 100%;
        padding: 50px 0px 50px 0px;
    }

    .titlesection-wrapper{
       
        flex-direction: column;
        padding: 20px 50px;
    }

    .titlesection-wrapper-content{
        width: 100%;
        /* margin-left: 100px; */
    }
}