.progress{
    width: 100%;
    height: 70vh;
    background-image: url('https://images.unsplash.com/photo-1449300079323-02e209d9d3a6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80');
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 50px;
}

.progress-wrapper{
    display: flex;
    align-items: center;
    margin: 50px 100px;
}

.progress-wrapper-left{
    flex:0.3;
    margin-top: 100px;
    padding-left: 100px;
}

.progress-wrapper-left h4{
    color: white;
    text-align: start;
    margin-bottom: -50px;
}

.progress-wrapper-left h5{
    color: white;
    font-size: 2.2rem;
    text-align: start;
    width: 70%;
    margin-bottom: -10px;
}

.progress-wrapper-left p{
    color: white;
    text-align: start;
    width: 70%;
}

.progress-wrapper-right{

    flex: 0.6;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 100px;
    width: 70%;
}

.cirle-cont p{
    color: white;
}

.circle{
    width: 100px;
    height: 100px;
}

@media screen and (max-width: 960px) {
    .progress{
        height: 100%;
    }
    .progress-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 50px 10px;
    }
    
    .progress-wrapper-left{
        flex:0.5;
        margin-top: 30px;
        padding-left: 10px;
    }
    
    .progress-wrapper-left h4{
        color: white;
        text-align: center;
        margin-bottom: 20px;
    }
    
    .progress-wrapper-left h5{
        color: white;
        font-size: 2.2rem;
        text-align: center;
        width: 100%;
        margin-bottom: -10px;
    }
    
    .progress-wrapper-left p{
        color: white;
        text-align: center;
        width: 100%;
    }
    
    .progress-wrapper-right{
    
        flex: 0.6;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 50px;
        width: 100%;
        margin-bottom: 50px;
    }
    
    .cirle-cont p{
        color: white;
    }
    
    .circle{
        width: 100px;
        height: 100px;
    }
}