
.navbar{
    width: 100%;
    height: 70px;
    background-color: #0E5E6F;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 999;
    align-items: center;
}

.nav-left{
    flex: 4;
    color: #fff;
    margin-left: 50px;
}


.nav-center{
    flex: 3;
    color: #fff;
    display: flex;
}

.nav-menu{
    display: flex;
    list-style: none;
    margin-left: 20px;
}

.topImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 30px;
}

.logo-container{
    width: 158px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
}

.navbar-logo{
    color: rgb(14, 62, 59);
    font-size: 1rem;
    width: 138px;
    height: 38px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

.menu-icon{
    display: none;
}

.nav-search{
    position: absolute;
    top: 30px;
    right: 200px;
}

.social-icon-link{
    font-size: 30px;
    margin-right: 10px;
    color: #fff;
    margin-left: 10px;
}

.nav-item{
    font-size: 14px;
    font-weight: 700;
    margin-right: 15px;

}

.nav-item:hover{
  border-bottom: 2px solid white;
}

.nav-item.mobile {
  display: none;
}

.nav-link{
    color: #fff;
    text-decoration: none;
}


@media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 50vh;
      margin-left: -20px;
      position: absolute;
      top: 55px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      overflow: hidden;
    }
  
    .nav-menu.active {
      background-color: #0E5E6F;
      left: -20px;
      opacity: 1;
      transition: all 0.5s ease;
      margin-top: 10px;
      z-index: 1;
    }
    
    .nav-left{
      flex: 4;
      color: #fff;
      margin-left: 20px;
  }

  
    .nav-item {
      text-align: center;
      padding: 1rem;
      width: 100%;
      display: table;
    }
  
    .nav-item:hover {
      /* background-color: #fff; */
      color: teal;
      border-radius: 0;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: -10px;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }

    .fa-bars{
        color: #fff;
    }

    .nav-item.l {
      display: none;
    }
  
    .nav-item.mobile {
      display: block;
      text-align: center;
      margin: .5rem auto;
      border-radius: 20px;
      width: 70%;
      text-decoration: none;
      font-size: 1.2rem;
      background-color: transparent;
      color: #fff;
      padding: 10px 15px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-item.mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }

    .fa-search{
        display: none;
    }
  }
  