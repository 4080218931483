.footer{
    height: 400px;
    background-color: #0E5E6F;
   
}

.footer-wrapper{
    display: flex;
    justify-content: space-between;
    margin: 0px 100px;
}


.footer-wrapper-left{
    margin-top: 50px;
}

.footer-wrapper-left p{
    font-size: 18px;
    color: #fff;
}

.footer-wrapper-right{
    display: flex;
    background: hsla(0,0%,100%,.09);
    border-radius: 17px;
    margin-top: 50px;
    padding-right: 50px;
}

.footer-wrapper-right-co{
    color: #fff;
}

.footer-wrapper-right-le{
    color: #fff;
}

.footer-wrapper-right-le ul{
    list-style: none;
    text-align: start;
}

.footer-wrapper-right-le ul li{
    font-size: 17px;
    margin-bottom: 15px;
    font-weight: 400;
    text-align: start;
}

.footer-wrapper-right-co ul{
    list-style: none;
}

.footer-wrapper-right-co ul li{
    font-size: 17px;
    margin-bottom: 15px;
    font-weight: 400;
    text-align: start;
}


@media screen and (max-width: 960px) {
    .footer{
        height: 100%;
        background-color: #0E5E6F;
       
    }
    .footer-wrapper{
        flex-direction: column;
        margin: 0px 50px;
        padding-bottom: 50px;
    }


    .footer-wrapper-right-le ul li{
        font-size: 15px;
        
    }
    
    .footer-wrapper-right-co ul li{
        font-size: 15px;
    }
}